.checkout-grid *{
  min-width: 0;
  min-height: 0;
}

//
// Base style
//

.checkout-widget{
  //position: relative;
  //
  //@include media-breakpoint-up(md){
  //  &:before{
  //    position: absolute;
  //    top: 16px;
  //    @if $text-direction == ltr {
  //      left: 20px;
  //    } @else {
  //      right: 20px;
  //    }
  //    bottom: 32px;
  //    content: '';
  //    display: block;
  //    width: 2px;
  //    background-color: $border-color;
  //  }
  //}
}

.checkout-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: theme-spaces(2);
  // border-top: 1px solid $border-color;
}

//.checkout-widget-body,
//.checkout-footer{
//@include media-breakpoint-up(md){
//  @if $text-direction == ltr {
//    padding-left: 48px;
//  } @else {
//    padding-right: 48px;
//  }
//}
//}

.section-header-checkout{
  border-bottom: 0;
  padding-bottom: 0;

  > span{
    //position: relative;
    display: inline-block;
    @if $text-direction == ltr {
      padding-right: 16px;
    } @else {
      padding-left: 16px;
    }
    //background-color: $body-bg;
    z-index: 2;
  }

  //&:after{
  //  content: "";
  //  position: absolute;
  //  height: 2px;
  //  top: 50%;
  //  @if $text-direction == ltr {
  //    left: 16px;
  //    right: 0;
  //  } @else {
  //    left: 0;
  //    right: 16px;
  //  }
  //  background-color: $primary;
  //  transform: translateY(-50%);
  //}
}

.checkout-steps-header-indicator{
  //background-color: $primary;
}

.checkout-steps-header{
  padding-bottom: theme-spaces(3);
  margin-bottom: 0;
  font-weight: normal;
}

.section-header-checkout{
  //margin-top: theme-spaces(4);
}

.section-header-checkout,
.checkout-step-remaining{
  //position: relative;

  //@include media-breakpoint-up(md){
  //  &:before{
  //    content: '';
  //    position: absolute;
  //    top: 50%;
  //    @if $text-direction == ltr {
  //      left: -35px;
  //    } @else {
  //      right: -35px;
  //    }
  //    width: 16px;
  //    height: 16px;
  //    border-radius: 50%;
  //    background-color: $primary;
  //    transform: translateY(-50%);
  //    z-index: 3;
  //
  //  }
  //}
}

.card {
  > .passenger-info {
    padding: theme-spaces(2) theme-spaces(3);

    hr {
      border: none;
    }
  }
}

// Payment, Confirmation
.page-ferry_payment,
.page-ferry_confirmation {
  .section-header-checkout {
    padding: 24px 16px;
    font-size: 20px;
    border: 1px solid $card-border-color;
    border-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
    background-color: $white;
    color: $primary;
    margin-bottom: 0;
  }

  h4.card-header {
    border-bottom: 0;
  }

  .passengers,
  .vehicles,
  .insurance,
  .voucher {
    padding: 16px;
    border: 1px solid $card-border-color;
    border-radius: 0 0 $border-radius $border-radius;
    background-color: $white;
    margin-bottom: 16px;

    .section-header {
      border: 0;
    }

    .card {
      border: 0;
      margin-bottom: 0;
    }

    .icon {
      color: $info;
    }
  }

  .insurance {
    > .card {

      &:last-child {
        border-bottom: 0;
      }
    }

    .expand-title {
      width: 100%;

      > h4 {
        display: inline-block;
        color: $black;
        min-width: 50%;
      }

      > .package-price {
        float: right;
        color: $black;
      }

      > a {
        display: inline-block;
        font-size: 14px;
      }
    }
  }
}
