.result-container{
  border: 0;
  box-shadow: 0 5px 15px rgba(0,0,0,.08)
}

// 
// Segments
// 

.result-widget-header{
  border-bottom: 1px solid $border-color;
}

.segment-wrapper {
  border-color: $white;
}

.segment-item{
  background-color: $gray-light;
  padding-left: theme-spaces(2);
  padding-right: theme-spaces(2);
  margin-left: theme-spaces(2);
  margin-right: theme-spaces(2);
}

.segment-item-duration{
  background-color: #e6e6e6;
}

.segment-details-content{
  padding: theme-spaces(3) theme-spaces(2);
  margin: 0 theme-spaces(2);
}

// 
// Connection
// 

.connection-info{
  background-color: $white;
}
