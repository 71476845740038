//$font-path : '../../../fonts';
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Lt.woff') format('woff');
//    font-weight: 300;
//    font-style: normal;
//    font-display: auto;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Bd.woff') format('woff');
//    font-weight: bold;
//    font-style: normal;
//    font-display: auto;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Rg.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//    font-display: auto;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Lt.woff') format('woff');
//    font-weight: 300;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Bd.woff') format('woff');
//    font-weight: bold;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Rg.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Lt.woff') format('woff');
//    font-weight: 300;
//    font-style: normal;
//    font-display: block;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Bd.woff') format('woff');
//    font-weight: bold;
//    font-style: normal;
//    font-display: block;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Rg.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//    font-display: block;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Lt.woff') format('woff');
//    font-weight: 300;
//    font-style: normal;
//    font-display: fallback;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Bd.woff') format('woff');
//    font-weight: bold;
//    font-style: normal;
//    font-display: fallback;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Rg.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//    font-display: fallback;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Lt.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Lt.woff') format('woff');
//    font-weight: 300;
//    font-style: normal;
//    font-display: optional;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Bd.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Bd.woff') format('woff');
//    font-weight: bold;
//    font-style: normal;
//    font-display: optional;
//}
//
//@font-face {
//    font-family: 'effra';
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot');
//    src: url('#{$font-path}/tripsta/Effra_W_Rg.eot?#iefix') format('embedded-opentype'),
//        url('#{$font-path}/tripsta/Effra_W_Rg.woff') format('woff');
//    font-weight: normal;
//    font-style: normal;
//    font-display: optional;
//}

// Color system
//
//

$white:         #FFFFFF;
$black:         #000000;
$success:       #4CAF50;
$warning:       #F0AD4E;
$danger:        #D9534F;
$gray-light:    #f2f2f2;
$gray-mid:      #999999;
$gray-dark:     #444444;


// Fonts
$font-family-sans-serif:      "effra",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

$text-muted:    $gray-mid;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            600;

// Buttons

$btn-border-radius:     4px;


// Navbar

$navbar-bg:                   $gray-dark;

$navbar-link-disabled-color:  $text-muted;
$navbar-link-color:           $white;
$navbar-link-hover-color:     $text-muted;
$navbar-link-active-color:    $white;

// Nav

$nav-pills-border-radius:  100px;


// Results

$masthead-bg: $gray-dark;
