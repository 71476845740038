$brand: 'travelplanet24';

// Color system, the others same as tripsta

$primary:       #2098C9;
$secondary:     #00A499;
$accent:        #84BD00;
$purple:        #981D97;

// Theme variables
@import '../tripsta/variables';


// Shared style
@import '../../theme/style';


@import '../tripsta/reboot';
@import '../tripsta/results-list';
@import '../tripsta/checkout-page';
@import 'navbar';
@import 'checkout-page';
